.cookie-policy {
  position: fixed;
  bottom: 15px;
  z-index: 99999;
  background: white;
  left: 15px;
  right: 15px;
  padding: 15px;
  padding-right: 45px;
  border: 2px solid var(--primary);
  border-radius: 6px;

  .btn-close {
    position: absolute;
    top: 6px;
    right: 18px;
    font-size: 22px;
    cursor: pointer;
    color: var(--gray-800);
    &:hover {
      color: var(--primary);
    }
  }
}
